import { useRouter } from 'next/router';
import { FormattedMessage as T } from 'react-intl';
import { useGlobalConfig } from '../hooks/configContext';
import BusinessUnitWrapper from './BusinessUnitWrapper';
import m from './Navigation/messages';

import MarketWrapper from './MarketWrapper';
import Link from './RainbowLink/RainbowLink';
import Spacer from './Spacer/Spacer';

const LegalLinks = () => {
  const { locale } = useRouter();
  const { STOREFRONT_URL, BU_CONFIG, urls: storefrontUrls } = useGlobalConfig();
  const privacyUrl =
    BU_CONFIG.supported_locales.length > 1
      ? `${STOREFRONT_URL}/${locale}/legal/privacy/`
      : `${STOREFRONT_URL}/legal/privacy/`;

  const year = new Date().getFullYear();

  const SpacerIcon = () => <Spacer type="lilac" className="inline-block" />;

  const linkClassName = 'text-lilac-20 text-sm font-normal whitespace-nowrap';
  return (
    <div className="pt-4">
      <BusinessUnitWrapper includeUnits={['vivi-it']}>
        <>
          <Link
            href="https://vivienergia.imgix.net/uploads/2020/04/03120534/Privacy-Policy_Aprile-20201.pdf"
            target="_blank"
            className={linkClassName}
          >
            <T {...m.privacyTitle} />
          </Link>
          <SpacerIcon />
        </>
      </BusinessUnitWrapper>
      <BusinessUnitWrapper includeUnits={['otovo-br']}>
        <Link
          href="https://holu.com.br/docs/politica-de-privacidade"
          target="_blank"
          className={linkClassName}
        >
          <T {...m.privacyTitle} />
        </Link>
        <SpacerIcon />
      </BusinessUnitWrapper>
      <BusinessUnitWrapper onlyCentral excludeUnits={['otovo-br']}>
        <Link href={privacyUrl} locale={locale} className={linkClassName}>
          <T {...m.privacyTitle} />
        </Link>
        <SpacerIcon />
      </BusinessUnitWrapper>
      {storefrontUrls?.imprintUrl && (
        <>
          <Link
            href={storefrontUrls.imprintUrl}
            className={linkClassName}
            target="_blank"
          >
            <T {...m.imprintTitle} />
          </Link>
          <SpacerIcon />
        </>
      )}
      {storefrontUrls?.termsPageUrl && (
        <BusinessUnitWrapper excludeUnits={['vivi-it', 'oktavia-it']}>
          <Link
            href={storefrontUrls.termsPageUrl}
            target="_blank"
            className={linkClassName}
          >
            <T {...m.ourConditions} />
          </Link>
          <SpacerIcon />
        </BusinessUnitWrapper>
      )}

      {storefrontUrls?.leasingTermsPageUrl && (
        <BusinessUnitWrapper excludeUnits={['vivi-it']}>
          <Link
            href={storefrontUrls.leasingTermsPageUrl}
            target="_blank"
            className={linkClassName}
          >
            <T {...m.ourLeasingConditions} />
          </Link>
          <SpacerIcon />
        </BusinessUnitWrapper>
      )}

      <MarketWrapper markets={['es']}>
        <>
          <Link
            href={`${STOREFRONT_URL}/legal/terms-of-use/`}
            target="_blank"
            className={linkClassName}
          >
            <T {...m.termsOfUse} />
          </Link>
          <SpacerIcon />
        </>
      </MarketWrapper>
      <MarketWrapper markets={['gb']}>
        <>
          <Link
            href="https://otovoweb.s3.eu-central-1.amazonaws.com/PDF/otovo_uk_idd_2024.pdf"
            target="_blank"
            className={linkClassName}
          >
            Initial Disclosure Document
          </Link>
          <SpacerIcon />
          <Link
            href="https://otovoweb.s3.eu-central-1.amazonaws.com/PDF/otovo_uk_complaints_procedure_2024.pdf"
            target="_blank"
            className={linkClassName}
          >
            Complaints Policy
          </Link>
          <SpacerIcon />
        </>
      </MarketWrapper>
      <MarketWrapper markets={['at']}>
        <>
          <Link
            href="https://otovoweb.s3.eu-central-1.amazonaws.com/PDF/otovo_at_complaints_procedure_2024.pdf"
            target="_blank"
            className={linkClassName}
          >
            Beschwerdeabwicklung
          </Link>
          <SpacerIcon />
        </>
      </MarketWrapper>
      <MarketWrapper markets={['pt']}>
        <>
          <Link
            href="https://www.livroreclamacoes.pt/Inicio/"
            target="_blank"
            className={linkClassName}
          >
            Livro de reclamações
          </Link>
          <SpacerIcon />
        </>
      </MarketWrapper>
      <p className="inline-block text-sm text-lilac-40">
        © {BU_CONFIG.company_name} {BU_CONFIG.legal_suffix} {year}
      </p>
    </div>
  );
};
export default LegalLinks;
