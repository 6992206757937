import { useTheme } from '@emotion/react';
import { cn } from '../../lib/classNames';

type Props = {
  className?: string;
  type: 'lilac' | 'grey';
};

const Spacer = ({ className, type }: Props) => {
  const { colors } = useTheme();
  const fill = type === 'grey' ? colors.grey_50 : colors.lilac_40;
  return (
    <span className={cn('px-1', className)}>
      <svg xmlns="http://www.w3.org/2000/svg" fill={fill} width="6" height="6">
        <circle cx="3" cy="3" r="1.5" />
      </svg>
    </span>
  );
};

export default Spacer;
