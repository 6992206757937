export const PUBLIC_S3_ASSET_BUCKET =
  'https://otovoweb.s3-eu-central-1.amazonaws.com';

export const FACEBOOK_URL = 'https://www.facebook.com/otovosolar';
export const INSTAGRAM_URL = 'https://www.instagram.com/otovosolar';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/otovo';
export const TWITTER_URL = 'https://twitter.com/otovosolar';
export const YOUTUBE_URL = 'https://www.youtube.com/@otovosolar5254/videos';

export const ADDRESS_SEARCH_FIELD_PLACEHOLDER = 'addressSearchField';
export const FAQ_PLACEHOLDER = 'faq';
export const SOLAR_SAVE_CLUB_PROMO_PLACEHOLDER = 'solarSaveClubPromo';
export const OTOVO_GUARANTEE_PLACEHOLDER = 'otovoGuarantee';
export const OTOVO_GUARANTEE_BATTERY_PLACEHOLDER = 'otovoGuaranteeBattery';
